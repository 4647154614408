import * as React from "react";
import { Link, graphql } from "gatsby";

import Layout from "../components/layout";
import NewsItem from "../components/news-item";
import Pagenation from "../components/pagenation";
import CategoryNave from "../components/categoryNave";

const pageTitle = "News";

const Category = ({ data, pageContext }) => {
  return (
    <Layout pageTitle={pageTitle}>
      <CategoryNave />
      <p>category - {data.microcmsNewsCategory.name}</p>
      {data.allMicrocmsNews.nodes.map((node) => {
        return <NewsItem postData={node} />;
      })}
      <div>
        <Link to="/news">News一覧</Link>
        <Link to="/news/category">カテゴリ一覧</Link>
      </div>
      <Pagenation
        totalPageCount={pageContext.pageCount}
        nowPage={pageContext.nowPage}
        directory={`news/category/${pageContext.slug}`}
      />
    </Layout>
  );
};

export default Category;

export const query = graphql`
  query ($slug: String, $skip: Int!, $limit: Int!) {
    microcmsNewsCategory(slug: { eq: $slug }) {
      name
      newsCategoryId
      slug
    }
    allMicrocmsNews(
      filter: { category: { slug: { eq: $slug } } }
      sort: { fields: publishedAt, order: DESC }
      skip: $skip
      limit: $limit
    ) {
      nodes {
        title
        publishedAt(formatString: "YYYY.MM.DD")
        newsId
        category {
          newsCategoryId
          name
          slug
        }
      }
      totalCount
    }
  }
`;
